<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR ANGKATAN</p>
          <router-link
            v-if="isAdmin"
            :to="{ name: 'angkatan-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <cari-input class="filter1" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="angkatanList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + angkatanList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="angkatan in angkatanList"
              :key="angkatan.id"
              :title="angkatan.mulai"
              :detailUrl="{
                name: 'angkatan-detail',
                params: { id: angkatan.id },
              }"
            ></box>
            <box v-if="angkatanList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="angkatanList"
            :mobile-cards="false"
          >
            <b-table-column
              field="no"
              label="No"
              v-slot="props"
            >{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="tahun" label="Tahun" v-slot="props">{{ props.row.tahun }}</b-table-column>
            <b-table-column field="mulai" label="Mulai" v-slot="props">
              <router-link
                :to="{ name: 'angkatan-detail', params: { id: props.row.id } }"
              >{{ props.row.mulai }}</router-link>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import AngkatanList from "../models/angkatanList.js";

export default {
  name: "AngkatanList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
  },
  data() {
    this.angkatanListMdl = new AngkatanList();
    this.listMdl = this.angkatanListMdl;
    return this.angkatanListMdl.getObservables();
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"]),
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>